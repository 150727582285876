import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { SvgIconCasino } from "~animations/__layers__/SvgIconCasino";
import { SvgIconPokerChip } from "~animations/__layers__/SvgIconPokerChip";
import { SvgIconSlotMachine } from "~animations/__layers__/SvgIconSlotMachine";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Map of bespoke iGaming related SVG icons as react components
 * ------------------------------------------------------------------------------
 */

const ICON_MAP = {
  SvgIconPokerChip,
  SvgIconCasino,
  SvgIconSlotMachine,
} as const;

type IconKey = keyof typeof ICON_MAP;

/**
 * ------------------------------------------------------------------------------
 * Abstraction of single item in the iGaming icons layer
 * ------------------------------------------------------------------------------
 */

interface AnimLayerIgamingIconsItemProps {
  icon: IconKey;
  text: string;
}

function AnimLayerIgamingIconsItem({
  icon,
  text,
  ...rest
}: AnimLayerIgamingIconsItemProps) {
  const Icon = ICON_MAP[icon];

  return (
    <StoryblokEditable {...rest}>
      <Box
        display="flex"
        borderRadius="sm"
        flexDirection="column"
        gap="phoneSpacing1"
        alignItems="center"
        justifyContent="center"
        className={variantBackgroundColor.background_sand}
        padding="phoneSpacing2"
        data-motion={ANIMATED.secondary}
      >
        <Box height="phoneSpacing3" width="phoneSpacing3">
          <Icon />
        </Box>
        <Box textAppearance="phone_text_sm">{text}</Box>
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Main component - renders a list of cards with iGaming related icons and text
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerIgamingIconsProps extends BoxProps, StoryblokBlok {
  items: Array<StoryblokBlok & AnimLayerIgamingIconsItemProps>;
}

export function AnimLayerIgamingIcons({
  items,
  ...rest
}: AnimLayerIgamingIconsProps) {
  if (!Array.isArray(items)) return null;

  return (
    <Box
      display="grid"
      __gridTemplateColumns="repeat(3, 1fr)"
      alignItems="stretch"
      gap="phoneSpacing1"
      {...rest}
    >
      {items.map((item) => {
        return <AnimLayerIgamingIconsItem key={item._uid} {...item} />;
      })}
    </Box>
  );
}
