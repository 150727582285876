import * as React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { AnimLayerTransactionList } from "../AnimLayerTransactionList";
import * as styles from "./styles.css";

import type { AnimLayerTransactionListItemProps } from "../AnimLayerTransactionListItem";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerExpenditureTabbedListProps
  extends Omit<BoxProps, "color"> {
  activeTab?: number;
  className?: string;
  tabs: Array<StoryblokBlok & { label: string }>;
  listItems: Array<StoryblokBlok & AnimLayerTransactionListItemProps>;
}

export function AnimLayerExpenditureTabbedList({
  // activeTab = 0,
  className: userClassName,
  listItems,
  tabs,
  ...rest
}: AnimLayerExpenditureTabbedListProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        className={clsx(
          userClassName,
          getSprinkles(atomProps),
          styles.container
        )}
        {...otherProps}
      >
        <Box className={styles.tabs}>
          {Array.isArray(tabs) &&
            tabs.map(({ label: tabLabel, ...tabRest }, index) => {
              return (
                <StoryblokEditable key={tabRest._uid} {...tabRest}>
                  <Box
                    data-motion={ANIMATED.secondary}
                    key={tabRest._uid}
                    className={clsx(
                      styles.tab,
                      index === 1 && styles.tabActive
                    )}
                  >
                    {tabLabel}
                  </Box>
                </StoryblokEditable>
              );
            })}
        </Box>

        {listItems && (
          <AnimLayerTransactionList
            isDateVisible={false}
            transactions={listItems}
          />
        )}
      </AnimatedElement>
    </StoryblokEditable>
  );
}
