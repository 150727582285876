import React, { useContext } from "react";

import { faMinusCircle } from "@fortawesome/sharp-regular-svg-icons/faMinusCircle";
import { faPlusCircle } from "@fortawesome/sharp-regular-svg-icons/faPlusCircle";
import { faTrash } from "@fortawesome/sharp-regular-svg-icons/faTrash";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { PageQueryContext } from "~context/PageQueryContext";

import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Abstract of single ecommerce item
 * ------------------------------------------------------------------------------
 */

export interface EcommerceItemProps {
  image?: StoryblokFieldMedia;
  title?: string;
  amount?: number;
}

function EcommerceItem({ image, title, amount, ...rest }: EcommerceItemProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <Box display="flex" alignItems="stretch" gap="phoneGutterWidth">
        {image && (
          <Image
            className={variantBackgroundColor.background_sand}
            borderRadius="sm"
            width="phoneSpacing20"
            loading="lazy"
            border="default"
            image={image}
          />
        )}
        <Box
          textAppearance="phone_text_md"
          width="100%"
          display="flex"
          flexDirection="column"
          gap="phoneSpacing1"
        >
          {title && (
            <Box data-motion={ANIMATED.secondary} fontWeight="semibold">
              {title}
            </Box>
          )}
          {amount && (
            <Box data-motion={ANIMATED.secondary}>
              {formatCurrencyString({
                amount,
                lang,
              })}
            </Box>
          )}
          <Box
            marginTop="auto"
            display="flex"
            alignItems="center"
            gap="phoneSpacing1"
            width="100%"
            data-motion={ANIMATED.secondary}
          >
            <IconFontAwesome icon={faMinusCircle} />
            {1}
            <IconFontAwesome icon={faPlusCircle} />
            <IconFontAwesome marginLeft="auto" icon={faTrash} />
          </Box>
        </Box>
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Component that renders a list of ecommerce items
 * (e.g. items someone may have in their cart in an ecommerce checkout )
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerEcommerceItemsProps extends BoxProps, StoryblokBlok {
  items: Array<StoryblokBlok & EcommerceItemProps>;
}

export function AnimLayerEcommerceItems({
  items,
  ...rest
}: AnimLayerEcommerceItemsProps) {
  if (!Array.isArray(items)) return null;

  return (
    <Box display="flex" flexDirection="column" gap="phoneSpacing2" {...rest}>
      {items.map((item) => {
        return <EcommerceItem key={item._uid} {...item} />;
      })}
    </Box>
  );
}
