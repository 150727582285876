import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { SvgIllustrationCards } from "~animations/__layers__/SvgIllustrationCards";
import { SvgIllustrationPokerChip } from "~animations/__layers__/SvgIllustrationPokerChip";

import { AnimLayerUiCardTitle } from "../AnimLayerUiCardTitle";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Map of bespoke iGaming related SVG illustrations as react components
 * ------------------------------------------------------------------------------
 */

const ILLUSTRATION_MAP = {
  SvgIllustrationCards,
  SvgIllustrationPokerChip,
} as const;

type IllustrationKey = keyof typeof ILLUSTRATION_MAP;

/**
 * ------------------------------------------------------------------------------
 * Abstraction of single item in the iGaming illustrations layer
 * ------------------------------------------------------------------------------
 */

interface AnimLayerIgamingCardsItemProps {
  illustration: IllustrationKey;
  title: string;
  description?: string;
}

function AnimLayerIgamingCardsItem({
  illustration,
  title,
  description,
  ...rest
}: AnimLayerIgamingCardsItemProps) {
  const Illustration = ILLUSTRATION_MAP[illustration];

  return (
    <StoryblokEditable {...rest}>
      <Box
        display="flex"
        minWidth="gridSpan2"
        maxHeight="phoneSpacing16"
        borderRadius="sm"
        alignItems="center"
        overflow="hidden"
        className={variantBackgroundColor.background_sand}
        data-motion={ANIMATED.secondary}
      >
        <Box padding="phoneSpacing3" flexShrink="0" width="50%">
          <Box
            textAppearance="phone_text_md"
            fontWeight="semibold"
            textTransform="uppercase"
            textAlign="center"
          >
            {title}
          </Box>
          {description && (
            <Box textAlign="center" textAppearance="phone_text_sm">
              {description}
            </Box>
          )}
        </Box>
        <Box minWidth="phoneSpacing16">
          <Illustration />
        </Box>
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Main component - renders a list of cards with iGaming related illustrations and title
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerIgamingCardsProps extends BoxProps, StoryblokBlok {
  items: Array<StoryblokBlok & AnimLayerIgamingCardsItemProps>;
  title?: string;
}

export function AnimLayerIgamingCards({
  items,
  title,
  ...rest
}: AnimLayerIgamingCardsProps) {
  if (!Array.isArray(items)) return null;

  return (
    <StoryblokEditable {...rest}>
      <Box {...rest}>
        {title && <AnimLayerUiCardTitle title={title} />}
        <Box display="flex" alignItems="stretch" gap="phoneSpacing1">
          {items.map((item) => {
            return <AnimLayerIgamingCardsItem key={item._uid} {...item} />;
          })}
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
