import React from "react";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";

import type { BoxProps } from "~components/Box";

const ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "<"] as const;

export function AnimLayerKeypad({ ...rest }: BoxProps) {
  return (
    <Box
      display="grid"
      alignItems="center"
      justifyItems="center"
      gap="gutterWidth"
      __gridTemplateColumns="repeat(3, 1fr)"
      data-motion={ANIMATED.secondary}
      {...rest}
    >
      {ARRAY.map((item) => {
        return (
          <Box key={item} fontWeight="bold" textAppearance="phone_text_lg">
            {item}
          </Box>
        );
      })}
    </Box>
  );
}
