import React, { useContext } from "react";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { PageQueryContext } from "~context/PageQueryContext";

import type { BoxProps } from "~components/Box";

export interface AnimLayerEcommerceOrderDetailsProps extends BoxProps {
  textShipping?: string;
  /** @todo Should this be a number? */
  amountShipping?: string;
  textTotal?: string;
  amountTotal?: number;
}

export function AnimLayerEcommerceOrderDetails({
  amountShipping,
  amountTotal,
  textShipping,
  textTotal,
  ...rest
}: AnimLayerEcommerceOrderDetailsProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <Box {...rest}>
        {(textShipping || amountShipping) && (
          <Box
            data-motion={ANIMATED.secondary}
            display="flex"
            justifyContent="space-between"
            textAppearance="phone_text_sm"
            color="text_lowContrast"
            marginBottom="phoneSpacing1"
          >
            {textShipping && <Box>{textShipping}</Box>}
            {amountShipping && <Box>{amountShipping}</Box>}
          </Box>
        )}
        {(textTotal || amountTotal) && (
          <Box
            data-motion={ANIMATED.secondary}
            display="flex"
            justifyContent="space-between"
            fontWeight="semibold"
          >
            {textTotal && <Box>{textTotal}</Box>}
            {amountTotal && (
              <Box>
                {formatCurrencyString({
                  amount: amountTotal,
                  lang,
                })}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </StoryblokEditable>
  );
}
