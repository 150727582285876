import React from "react";

import { faBuildingColumns } from "@fortawesome/sharp-regular-svg-icons/faBuildingColumns";
import { faCalendar } from "@fortawesome/sharp-regular-svg-icons/faCalendar";
import { faCheck } from "@fortawesome/sharp-regular-svg-icons/faCheck";
import { faChevronRight } from "@fortawesome/sharp-regular-svg-icons/faChevronRight";
import { faCoins } from "@fortawesome/sharp-regular-svg-icons/faCoins";
import { faCreditCard } from "@fortawesome/sharp-regular-svg-icons/faCreditCard";
import { faFile } from "@fortawesome/sharp-regular-svg-icons/faFile";
import { faMoneyBill } from "@fortawesome/sharp-regular-svg-icons/faMoneyBill";

import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerUiCardTitle } from "../AnimLayerUiCardTitle";
import { AnimLayerUiRadioButton } from "../AnimLayerUiRadioButton";
import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

const ICON_MAP = {
  faBuildingColumns,
  faCreditCard,
  faMoneyBill,
  faCoins,
  faCheck,
  faCalendar,
  faFile,
} as const;

/**
 * ------------------------------------------------------------------------------
 * Abstraction of single payment method
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerIconListItemProps extends StoryblokBlok {
  ctaText?: string;
  description?: string;
  icon: keyof typeof ICON_MAP;
  isArrowVisible?: boolean;
  isRadioButtonChecked?: boolean;
  isRadioButtonVisible?: boolean;
  title: string;
  appearance?: styles.VariantIconListItemAppearanceEnum;
}

export function AnimLayerIconListItem({
  ctaText,
  description,
  icon,
  isArrowVisible,
  isRadioButtonChecked,
  isRadioButtonVisible,
  title,
  appearance,
  ...rest
}: AnimLayerIconListItemProps) {
  const dynamicIcon = ICON_MAP[icon] || undefined;

  return (
    <StoryblokEditable {...rest}>
      <Box
        data-motion={ANIMATED.secondary}
        className={styles.getIconListItemStyles({ appearance })}
      >
        <Box
          alignItems="center"
          display="flex"
          gap="phoneSpacing1"
          paddingX="phoneSpacing1"
          paddingY="phoneSpacing2"
          textAppearance="phone_text_sm"
        >
          {dynamicIcon && (
            <IconFontAwesome color="text_lowContrast" icon={dynamicIcon} />
          )}
          {title && (
            <Box fontWeight="semibold" color="text_lowContrast">
              {title}
            </Box>
          )}
          {isArrowVisible && !ctaText && !isRadioButtonVisible && (
            <IconFontAwesome
              marginLeft="auto"
              fontWeight="semibold"
              icon={faChevronRight}
              color="text_lowContrast"
            />
          )}
          {!ctaText && isRadioButtonVisible && (
            <AnimLayerUiRadioButton
              marginLeft="auto"
              marginRight="phoneSpacing1"
              isChecked={isRadioButtonChecked}
            />
          )}
          {ctaText && (
            <Box
              marginLeft="auto"
              __color={colorTokens_latest.Secondary.Water_azure[130].value}
            >
              {ctaText}
            </Box>
          )}
        </Box>

        {description && (
          <Box
            paddingX="phoneSpacing1"
            textAppearance="phone_text_sm"
            color="text_lowContrast"
          >
            {description}
          </Box>
        )}
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Component that renders mock ui elements showing a list of available payment methods
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerIconListProps extends StoryblokBlok, BoxProps {
  items: Array<StoryblokBlok & AnimLayerIconListItemProps>;
  areRadioButtonsVisible?: boolean;
  title?: string;
  isArrowVisible?: boolean;
  appearance?: styles.VariantIconListItemAppearanceEnum;
}

export function AnimLayerIconList({
  items,
  title,
  className: userClassName,
  appearance,
  isArrowVisible = true,
  ...rest
}: AnimLayerIconListProps) {
  if (!Array.isArray(items)) return null;

  return (
    <StoryblokEditable {...rest}>
      <Box {...rest} className={userClassName}>
        {title && <AnimLayerUiCardTitle title={title} />}

        <Box>
          {items.map((item) => {
            return (
              <AnimLayerIconListItem
                isArrowVisible={isArrowVisible}
                appearance={appearance}
                key={item._uid}
                {...item}
              />
            );
          })}
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
