import React, { useContext } from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { PageQueryContext } from "~context/PageQueryContext";

import { AnimLayerUiRadioButton } from "../AnimLayerUiRadioButton";

import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Abstract of single ecommerce item
 * ------------------------------------------------------------------------------
 */

interface EcommerceRefundItemProps {
  image?: StoryblokFieldMedia;
  title?: string;
  amount?: number;
  index?: number;
  textItemCount: string;
  areRadioButtonsVisible?: boolean;
}

function EcommerceRefundItem({
  amount,
  areRadioButtonsVisible,
  image,
  index,
  textItemCount,
  title,
  ...rest
}: EcommerceRefundItemProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <Box display="flex" alignItems="center" gap="phoneSpacing2">
        {areRadioButtonsVisible && (
          <AnimLayerUiRadioButton isChecked={index === 0} />
        )}

        <Box
          display="flex"
          maxWidth="100%"
          alignItems="stretch"
          gap="phoneGutterWidth"
        >
          {image && (
            <Image
              className={variantBackgroundColor.background_sand}
              borderRadius="sm"
              width="phoneSpacing12"
              loading="lazy"
              flexShrink="0"
              border="default"
              image={image}
            />
          )}
          <Box
            textAppearance="phone_text_md"
            flexGrow="1"
            display="flex"
            flexDirection="column"
            gap="phoneSpacing1"
          >
            {title && (
              <Box data-motion={ANIMATED.secondary} fontWeight="semibold">
                {title}
              </Box>
            )}
            {amount && (
              <Box data-motion={ANIMATED.secondary}>
                {formatCurrencyString({
                  amount,
                  lang,
                })}
              </Box>
            )}
            {textItemCount && (
              <Box
                data-motion={ANIMATED.secondary}
                textAppearance="phone_text_sm"
                color="text_lowContrast"
              >
                {textItemCount}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Component that renders a list of ecommerce items
 * (e.g. items someone may have in their cart in an ecommerce checkout )
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerEcommerceRefundItemsProps
  extends BoxProps,
    StoryblokBlok {
  items: Array<StoryblokBlok & EcommerceRefundItemProps>;
  areRadioButtonsVisible?: boolean;
}

export function AnimLayerEcommerceRefundItems({
  items,
  areRadioButtonsVisible,
  ...rest
}: AnimLayerEcommerceRefundItemsProps) {
  if (!Array.isArray(items)) return null;

  return (
    <StoryblokEditable {...rest}>
      <Box display="flex" flexDirection="column" gap="spacing2" {...rest}>
        {items.map((item, index) => {
          return (
            <EcommerceRefundItem
              areRadioButtonsVisible={areRadioButtonsVisible}
              key={item._uid}
              index={index}
              {...item}
            />
          );
        })}
      </Box>
    </StoryblokEditable>
  );
}
